




























































































































































import Vue from 'vue';
import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Songoro app features management",
      items: [
        {
          text: "Venues",
          href: "/venues",
        },
        {
          text: "Detail",
          href: "/venue",
          active: true
        }
      ],
    };
  }
});
